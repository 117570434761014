$( ( $ ) => {

  const triggerElement = $( '.menu-search-icon .mega-menu-link' );
  const closeIcon = $( '.search-close' );
	
  $( document ).on( 'click', '.menu-search-icon .mega-menu-link', ( e ) => {
    
    e.stopImmediatePropagation();
    e.preventDefault();
    if ( e.target === triggerElement[0] ) {
      const parentElement = $( e.target ).parent();
      parentElement.toggleClass( 'open-search' );
      $( e.target ).find( 'input[type="text"]' ).get( 0 ).focus();
      if ( parentElement.siblings().hasClass( 'mega-toggle-on' ) ) {
        parentElement.siblings().removeClass( 'mega-toggle-on' );
      }
    }
  } );


  document.addEventListener( 'click', function( event ) {
    let isClickInside = triggerElement[0].contains( event.target );
    if ( !isClickInside ) {
      triggerElement.parent().removeClass( 'open-search' );
    }
  } );
	
  closeIcon.on( 'click', () => {
    triggerElement.parent().removeClass( 'open-search' );
  } );
	
} );

