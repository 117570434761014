$( window ).on( 'load', function () {
  if ( !$( '.bs-section--quiz-section' ).length ) return;

  $( ( $ ) => {
    const selectedValue = {};
    const count = { A: 0, B: 0, C: 0 };

    const handleRadioChange = ( item ) => {
      const name = item.attr( 'name' );
      const value = item.val();

      if ( selectedValue[name] ) {
        count[selectedValue[name]] -= 1;
      }
      count[value] += 1;
      selectedValue[name] = value;
    };

    const calculateLastScore = () => {
      const result = Object.entries( count ).reduce(
        ( acc, [key, value] ) => {
          if ( value > acc.maxValue ) {
            return { maxValue: value, keys: [key] };
          }
          if ( value === acc.maxValue ) {
            acc.keys.push( key );
          }
          return acc;
        },
        { maxValue: -Infinity, keys: [] }
      );

      const { keys } = result;

      if ( keys.length === 1 ) {
        return { A: 1, B: 2, C: 3 }[keys[0]];
      }

      if ( keys.includes( 'A' ) && keys.includes( 'B' ) ) return 4;
      if ( keys.includes( 'A' ) && keys.includes( 'C' ) ) return 5;
      if ( keys.includes( 'B' ) && keys.includes( 'C' ) ) return 6;
      return 7;
    };

    const updateLastScore = () => {
      const lastScore = calculateLastScore();
      $( '.bs-section--quiz-section input[name="last_score"]' )
        .val( lastScore )
        .trigger( 'input' )
        .trigger( 'change' );
    };

    const observeForm = () => {
      const form = document.querySelector( '.fluentform' );
      if ( !form ) return;

      $( '.bs-section--quiz-section .question input[type="radio"]' ).each( ( _, ele ) => {
        const item = $( ele );
        selectedValue[item.attr( 'name' )] = '';
        item.on( 'change', ( e ) => handleRadioChange( $( e.currentTarget ) ) );
      } );

      $( '.bs-section--quiz-section .ff-btn-next' ).on( 'click', updateLastScore );
    };

    const observer = new MutationObserver( () => {
      observeForm();
      observer.disconnect();
    } );

    observer.observe( document.body, { childList: true, subtree: true } );
  } );

} );

